<template>
    <div class="box my-account">
        <div class="consumption">
            <div class="consumption_head">
                <div class="consumption_head_but">
                    <el-radio-group v-model="typeData" @change="changeType">
                        <el-radio-button :label="item.value" v-for="(item, index) of typeList" :key="index">
                            {{ item.name }}
                        </el-radio-button>
                    </el-radio-group>
                </div>
                <div class="consumption_head_shop">
                    <el-button @click="shopCoupons" type="primary">购买消费券</el-button>
                </div>
            </div>
            <div class="consumption_table">
                <el-table :data="consumptionData">
                    <el-table-column label="订单号" width="125px" prop="order_no"></el-table-column>
                    <!--                    <el-table-column label="企业名称" prop="org_name"></el-table-column>-->
                    <el-table-column label="券信息" width="150px" align="center">
                        <template slot-scope="scope">
                            <div class="couponsTable">
                                <div class="couponsTable_Con">
                                    <div>
                                        <div>面值</div>
                                        <div v-for="(item, index) of scope.row.face_price" :key="index">{{ item }}
                                        </div>
                                    </div>
                                    <div>
                                        <div>数量</div>
                                        <div v-for="(item, index) of scope.row.face_price_num" :key="index">{{ item }}
                                        </div>
                                    </div>
                                    <div>
                                        <div>券总值</div>
                                        <div v-for="(item, index) of scope.row.face_price" :key="index">{{ item *
                                                scope.row.face_price_num[index]
                                        }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单总价" width="120px" prop="total_price"></el-table-column>
                    <el-table-column label="购买数量" width="95px" prop="buy_num"></el-table-column>
                    <el-table-column label="购买时间" width="100px" prop="create_time"></el-table-column>
                    <el-table-column label="状态" width="80px" prop="show_status"></el-table-column>
                    <el-table-column label="操作" width="180px">
                        <template slot-scope="scope">
                            <el-button class="layui-btn layui-btn-xs" @click="openData(scope.row, 'see')">查看</el-button>
                            <el-button class="layui-btn layui-btn-xs" @click="openAuditReasonsList(scope.row, 'see')">
                                审核记录</el-button>
                            <el-button class="layui-btn layui-btn-xs" v-if="scope.row.show_status === '待支付'"
                                @click="openData(scope.row, 'edit')">修改及上传支付凭证
                            </el-button>
                            <el-button class="layui-btn layui-btn-xs" v-if="scope.row.show_status === '已发放'"
                                @click="orgVoucherListData(scope.row)">查看券码</el-button>
                            <el-button class="layui-btn layui-btn-xs" v-if="scope.row.show_status === '已发放'"
                                @click="openNewData(scope.row)">导出券码</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="consumption_page">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page.sync="page.page" :page-size="page.page_size" layout="total, prev, pager, next"
                    :total="pageTotal">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="购买消费券" :before-close="handleClose" :visible.sync="showCoupons" width="900px">
            <div class="coupons">
                <el-form :model="shopCouponsData" label-width="100px" :rules="rules" ref="ruleForm"
                    :disabled="disabledForm">
                    <el-card class="box-card">
                        <div slot="header" class="coupons_title">
                            <span>消费券信息</span>
                            <span>合计金额：{{ moneytotal }} 元</span>
                        </div>
                        <div class="coupons_money">
                            <p class="coupons_tip">注意：单张爱心消费帮扶券面值最大为5000元，若您单位是集中采购的，请按实际情况购买，每购买一张消费券都需要激活一次。</p>
                            <el-form-item prop="type" label="消费券类型">
                                <el-select v-model="shopCouponsData.type" placeholder="请选择消费券类型" style="width:53%">
                                    <el-option v-for="(item, index) of xfList" :key="index" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="type_id" label="消费券种类">
                                <el-select v-model="shopCouponsData.type_id" placeholder="请选择消费券种类" style="width:53%">
                                    <el-option v-for="(item, index) of voucherTypeList" :key="index" :label="item.type_name"
                                        :value="item.type_id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <div class="coupons_money_list" v-for="(item, index) of moneyList" :key="index">
                                <div class="coupons_money_list_label">
                                    <span>面值</span>
                                </div>
                                <div class="coupons_money_list_input">
                                    <el-input @change="calculation" placeholder="请输入面值" max-length="8"
                                        v-model="item.money"
                                        oninput="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/,'$1$2.$3')">
                                        <template slot="append">元</template>
                                    </el-input>
                                </div>
                                <div class="coupons_money_list_label">
                                    <span>数量</span>
                                </div>
                                <div class="coupons_money_list_input">
                                    <el-input-number @change="calculation" v-model="item.num" :min="1" :max="10000"
                                        label="描述文字"></el-input-number>
                                </div>
                                <div>
                                    总面值: {{ item.money * item.num }} 元
                                </div>
                                <div v-show="!disabledButton">
                                    <el-button v-if="index + 1 === moneyList.length" @click="addMoney" type="success"
                                        icon="el-icon-plus" circle></el-button>
                                    <el-button @click="delMoney(index)" v-if="moneyList.length > 1" type="danger"
                                        icon="el-icon-minus" circle></el-button>
                                </div>
                            </div>

                        </div>
                    </el-card>
                    <el-divider></el-divider>
                    <el-card class="box-card" v-if="shopCouponsData.pay_status == 0 ? true : false">
                        <div slot="header" class="coupons_title"><span>对公转账信息</span></div>
                        <div class="coupons_money">
                            <el-form-item label="开户名称：">{{ shop_config.bank_account_name || '无' }}</el-form-item>
                            <el-form-item label="开户银行：">{{ shop_config.bank_address || '无' }}{{ shop_config.bank_name ||
                                    ''
                            }}
                            </el-form-item>
                            <el-form-item label="账    号：">{{ shop_config.bank_account_no || '------' }}</el-form-item>
                        </div>
                    </el-card>
                    <el-divider v-if="shopCouponsData.pay_status == 0 ? true : false"></el-divider>
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>开票信息 <label class="ns-text-color">（注：只能开增值税普通发票）</label></span>
                        </div>
                        <el-form-item prop="invoice_id" label="发票信息">
                            <el-select v-model="shopCouponsData.invoice_id" placeholder="请选择发票信息" style="width: 80%">
                                <el-option v-for="(item, index) of generalList" :key="index"
                                    :label="item.company_name + '(' + item.company_no + ')'" :value="item.invoice_id">
                                </el-option>
                            </el-select>
                            &nbsp;
                            <el-button icon="el-icon-refresh" circle @click="refresh"></el-button>
                            <!--<router-link :to="{ path: '/member/invoice_info' }" target="_blank" class="el-button el-button&#45;&#45;default"
                                         style="position: absolute;top:0;right:10px;">
                                            添加
                            </router-link>-->
                            <a @click="openGeneralListData" class="el-button el-button--default"
                                style="position: absolute;top:0;right:10px;">添加</a>
                        </el-form-item>
                        <el-form-item prop="invoice_email" label="邮箱">
                            <el-input v-model="shopCouponsData.invoice_email" placeholder="邮箱" type="text"></el-input>
                        </el-form-item>
                        <el-form-item prop="user_remark" label="备注">
                            <el-input v-model="shopCouponsData.user_remark" placeholder="备注" type="textarea"></el-input>
                        </el-form-item>
                        <el-form-item prop="pay_prove" label="支付凭证"
                            v-if="showStatus === 'edit' || showStatus === 'see'">
                            <template v-if="showStatus != 'see'">
                                <el-upload :action="uploadActionUrl" list-type="picture-card"
                                    :on-preview="handlePictureCardPreview" :limit="5" :on-success="handleAvatarSuccess"
                                    :on-exceed="handleExceed" :file-list="fileList" :before-upload="beforeAvatarUpload"
                                    :on-remove="handleRemove">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog append-to-body :visible.sync="showImg">
                                    <img width="100%" :src="dialogImageUrl" alt="" style="width: 70px">
                                </el-dialog>
                            </template>
                            <template v-else>
                                <div class="img-list" v-if="shopCouponsData.pay_prove">
                                    <el-image v-for="(img, img_index) in shopCouponsData.pay_prove" :key="img_index"
                                        :src="$img(img)" :preview-src-list="shopCouponsData.pay_prove"></el-image>
                                </div>
                            </template>
                        </el-form-item>
                    </el-card>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" v-show="!disabledButton">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="券码列表" @close='closeDialogVoucher' :visible.sync="VoucherListshow" width="1200">
            <div class="voucherListshow">
                <div>
                    <span style="display: inline-block">&nbsp;&nbsp;&nbsp;关键词： </span>
                    <div style="display: inline-block;width: 40%">
                        <el-input v-model="VoucherPage.keywords" placeholder="请输入券号/姓名/手机号码">
                            <div style="margin: 0;" slot="append">
                                <span class="verification" @click="orgVoucherListData">查询</span>
                            </div>
                        </el-input>
                    </div>
                </div>
                <el-table :data="VoucherList" v-loading="Voucherloading" style="width: 1200px">
                    <el-table-column label="激活人姓名" prop="receive_nickname" width="100px"></el-table-column>
                    <el-table-column label="激活人手机" prop="receive_mobile" width="150px"></el-table-column>
                    <el-table-column label="激活时间" prop="receive_time" width="150px"></el-table-column>
                    <el-table-column label="面值" prop="face_price" width="80px"></el-table-column>
                    <el-table-column label="消费券号" prop="voucher_number" width="180px"></el-table-column>
                    <el-table-column label="激活码" prop="voucher_pwd" width="110px"></el-table-column>
                    <el-table-column label="激活状态" prop="receive_status" width="80px">
                        <div slot-scope="scope">
                            {{ scope.row.receive_status | receiveStatus }}
                        </div>
                    </el-table-column>
                    <el-table-column label="订单号" prop="order_no" width="220px"></el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentVoucherChange" :current-page.sync="VoucherPage.page"
                    :page-size="VoucherPage.page_size" layout="total, prev, pager, next" :total="VoucherPageTotal">
                </el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialogVoucher">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog title="审核记录" :visible.sync="AuditReasonsShow" width="70%">
            <div>
                <el-table :data="AuditReasonsList" v-loading="AuditReasonsloading">
                    <el-table-column label="审核时间" prop="create_time"></el-table-column>
                    <el-table-column label="操作状态" prop="action"></el-table-column>
                    <el-table-column label="说明" prop="reasons"></el-table-column>
                    <el-table-column label="审核人" prop="u_username"></el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="AuditReasonsShow = false">关 闭</el-button>
            </span>
        </el-dialog>

        <el-dialog title="增值税普通发票信息" :visible.sync="formDataDialogVisible" @close='closeDialog'>
            <el-form ref="ruleForm" :model="formDataDialog" label-width="160px">
                <el-form-item label="发票抬头">
                    <el-input v-model="formDataDialog.company_name" :rules="[{ required: true, message: '不能为空' }]"
                        style="width: 360px" placeholder="发票抬头" class="ns-len-input"></el-input>
                </el-form-item>
                <el-form-item label="纳税人识别号" prop="company_no">
                    <el-input :rules="[{ required: true, message: '不能为空' }]" v-model="formDataDialog.company_no"
                        placeholder="单位信用码" style="width: 360px" maxlength="50" show-word-limit class="ns-len-input">
                    </el-input>
                </el-form-item>
                <el-input v-model="formDataDialog.invoice_type" style="width: 100px" type="hidden"></el-input>
                <el-input v-model="formDataDialog.invoice_id" style="width: 100px" type="hidden"></el-input>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="formDataDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveInvoice()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    voucherOrderbuy,
    voucherOrderlists,
    voucherTypelists,
    getAuditReasonsList,
    voucherOrderInfo,
    voucherOrderEdit,
    orgVoucherList,
    getGeneralList,
    exportVoucher, createInvoice, updateInvoice
} from "@/api/member/member"
import Config from "@/utils/config"
import Utli from "@/utils/util"
import { mapGetters } from "vuex"

export default {
    name: "consumptionCoupons",
    data() {
        var usernameValidata = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号！"))
            } else if (!Utli.checkPhone(value)) {
                callback(new Error("请输入正确的手机号码!"))
            } else {
                callback()
            }
        }
        var emailValidata = (rule, value, callback) => {
            //var reg = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/;
            var reg = /([\w\.\_]{2,10})@(\w{1,}).([a-z]{2,4})/;
            if (value === "") {
                callback(new Error("请邮箱地址！"))
            } else if (!reg.test(value)) {
                callback(new Error("邮箱格式不正确!"))
            } else {
                callback()
            }
        }
        return {
            formDataDialogVisible: false,
            formDataDialog: {
                company_name: "", // 单位名称(发票抬头)
                company_no: "", // 纳税人识别号
                is_default: 0 // 默认开票项
            },
            moneyList_but_show: true,
            VoucherListshow: false,
            Voucherloading: false,
            VoucherList: [],
            AuditReasonsShow: false,
            AuditReasonsloading: false,
            AuditReasonsList: [],
            VoucherPage: {
                page: 1,
                page_size: 10,
                order_no: '',
                keywords: ''
            },
            voucherTypeList:[],
            VoucherPageTotal: 0,
            uploadActionUrl: Config.baseUrl + "/api/upload/headimg",// 图片上传地址
            shopCouponsData: {
                type_id: '',
                pay_prove: [],// 支付凭证
                invoice_email: "",// 邮箱
                type: "",// 消费券类型 1 电子券 2 消费券
                invoice_id: "",// 消费券类型 1 电子券 2 消费券
                user_remark: ""// 用户备注
            },
            fileList: [],
            pageTotal: 0,
            showStatus: "add",
            dialogImageUrl: "",
            showImg: false,
            disabledForm: false,
            disabledButton: false,
            generalList: [], //发票集合
            xfList: [
                {
                    label: "电子消费券",
                    value: 1
                },
                {
                    label: "实体消费券",
                    value: 2
                }
            ],
            moneyList: [
                {
                    money: 1,
                    num: 1
                }
            ],
            typeData: "all",
            showCoupons: false,
            page: {
                page: 1,
                page_size: 10
            },
            typeList: [
                {
                    name: "全部",
                    value: "all"
                },
                {
                    name: "待支付",
                    value: "paying"
                },
                {
                    name: "已支付",
                    value: "payed"
                },
                // {
                //   name: "待审核",
                //   value: "review"
                // },
                {
                    name: "待发放",
                    value: "stayout"
                },
                {
                    name: "已发放",
                    value: "finish"
                }
            ],
            moneytotal: 0,
            rules: {
                invoice_org_name: [
                    { required: true, message: "请输入单位名称", trigger: "blur" },
                    { min: 3, max: 50, message: "长度在 3 到 50 个字符", trigger: "blur" }
                ],
                invoice_number: [
                    { required: true, message: "请输入纳税人识别号", trigger: "blur" }
                ],
                invoice_email: [
                    { required: true, validator: emailValidata, trigger: "blur" }
                ],
                address_detail: [
                    { required: true, message: "请输入地址", trigger: "blur" }
                ],
                invoice_tel: [
                    { required: true, validator: usernameValidata, trigger: "blur" }
                ],
                user_remark: [
                    { required: false, message: "用户备注", trigger: "blur" }
                ],
                invoice_id: [
                    { required: true, message: "请选择发票信息", trigger: "blur" }
                ],
                type: [
                    { required: true, message: "请选择消费券类型", trigger: "blur" }
                ],
                type_id: [
                    { required: true, message: "请选择消费券种类", trigger: "blur" }
                ],
                pay_prove: [
                    { required: true, message: "请上传支付凭证", trigger: "blur" }
                ]
            },
            consumptionData: [],
            order_no: "",
            shop_config: {}
        }
    },
    filters: {
        receiveStatus: function (name) {
            if (name == 0) {
                return '未激活'
            } else {
                return '已激活'
            }
        }
    },
    computed: {
        ...mapGetters(["wapQrcode", "member", "addonIsExit", "city"]),
        member: function () {
            return this.$store.getters.member
        },
    },
    watch: {
        member: function (a, b) {
            if (a.rank_type != 2) {
                this.$router.push('/member/index')
            }
        }
    },
    created() {
    },
    mounted() {
        //  消费券订单 / 订单列表
        this.voucherOrderlistsData()
        this.getVoucherType()
    },
    methods: {
        /** 弹窗关闭事件 */
        closeDialog() {
            this.deleteFormData()
        },
        closeDialogVoucher() {
            this.VoucherPage.keywords = ''
            this.VoucherListshow = false
        },
        saveInvoice() {
            if (this.formDataDialog.company_name.length == 0) {
                this.$message.error("发票抬头不能为空")
                return
            }
            if (this.formDataDialog.company_no.length == 0) {
                this.$message.error("纳税人识别号不能为空")
                return
            }
            var data = {
                invoice_type: 1,
                company_name: this.formDataDialog.company_name,
                company_no: this.formDataDialog.company_no,
                is_default: this.formDataDialog.is_default
            }
            createInvoice(data)
                .then(res => {
                    if (res.code == 0) {
                        this.formDataDialogVisible = false
                        this.deleteFormData()
                        // 发票信息列表
                        this.refresh()
                        this.$message.success("保存成功!")
                    } else {
                        this.flag = false
                        this.$message({ message: res.message, type: "warning" })
                    }
                })
                .catch(err => {
                    this.flag = false
                    this.$message.error(err.message)
                })

        },
        // 开票信息
        openGeneralListData() {
            this.formDataDialogVisible = true
        },
        // 清空弹窗表单数据
        deleteFormData() {
            this.formDataDialog.invoice_id = ""
            this.formDataDialog.company_name = ""
            this.formDataDialog.company_no = ""
            this.formDataDialog.is_default = ""
        },
        refresh() {
            this.getGeneralListData() //发票信息
        },
        // 导出券码
        openNewData(item) {
            let data = {
                order_no: item.order_no
            }
            exportVoucher(data).then(res => {
                let data = res.data
                if (data) {
                    window.open(data)
                } else {
                    this.$message.error('无导出数据！')
                }
            })
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then(_ => {
                    this.moneyList = []
                    this.fileList = []
                    this.$refs["ruleForm"].resetFields()
                    this.showCoupons = false
                    this.moneyList_but_show = true;
                    done()
                })
                .catch(_ => {
                })
        },
        // 查看详情
        openData(row, status) {
            this.showCoupons = !this.showCoupons
            this.moneyList_but_show = false;
            this.showStatus = status
            this.order_no = row.order_no

            if (status === "see") {
                this.disabledForm = true
                this.disabledButton = true
            } else {
                this.disabledForm = false
                this.disabledButton = false
            }
            let data = {
                order_no: row.order_no
            }
            this.getGeneralListData() //发票信息
            voucherOrderInfo(data).then(res => {
                this.moneyList = []
                this.fileList = []
                let list = res.data
                list.face_price = list.face_price.split(",")
                list.face_price_num = list.face_price_num.split(",")
                list.face_price.map((item, index) => {
                    this.moneyList.push({
                        money: item,
                        num: list.face_price_num[index]
                    })
                })
                if (list.pay_prove) {
                    list.pay_prove = JSON.parse(list.pay_prove)
                    if (list.pay_prove.length) {
                        list.pay_prove.map(item => {
                            this.fileList.push({
                                url: item
                            })
                        })
                    }
                }

                let par = {
                    invoice_org_name: list.invoice_org_name,// 单位名称
                    invoice_number: list.invoice_number,// 纳税人识别号
                    invoice_email: list.email,// 联系人邮箱
                    invoice_tel: list.tel,// 联系人手机
                    pay_prove: list.pay_prove || [],// 支付凭证
                    type: list.type,// 消费券类型 1 电子券 2 消费券
                    type_id:list.type_id,
                    invoice_id: list.invoice_id,// 发票信息
                    user_remark: list.user_remark, // 用户备注
                    address_detail: list.address_detail,// 地址
                    pay_status: list.pay_status// 支付状态
                }
                this.shopCouponsData = par
                this.calculation()
            }).catch(e => {
                this.$message.warning(e.message)
            })
            // this.shopCouponsData = row
        },
        handleAvatarSuccess(res, file) {
            let data = res.data
            this.shopCouponsData.pay_prove.push(data.pic_path)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg" || "image/png"
            const isLt2M = file.size / 1024 / 1024 < 10

            if (!isJPG) {
                this.$message.error("上传图片只能是 JPG ,png格式!")
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 10MB!")
            }
            return isJPG && isLt2M
        },
        handleRemove(file, fileList) {
            let list = []
            fileList.map(item => {
                if (item.response) {
                    list.push(item.response.data.pic_path)
                } else {
                    list.push(item.url)
                }
            })
            this.shopCouponsData.pay_prove = list
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 5 个文件!`)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.showImg = true
        },
        // 状态修改查询
        changeType(value) {
            this.page.page = 1
            this.voucherOrderlistsData()
        },
        getVoucherType(){
            voucherTypelists({}).then(res=>{
                let data = res.data;
                this.voucherTypeList = data;
            }).catch(e=>{
                this.$message.warning(e.message)
            })
        },
        //  消费券订单 / 订单列表
        voucherOrderlistsData() {
            let data = {
                ...this.page,
                status: this.typeData
            }
            voucherOrderlists(data).then(res => {
                let data = res.data
                this.pageTotal = data.count;
                this.shop_config = res.shop_config;
                this.consumptionData = data.list.filter(item => {
                    item.face_price = item.face_price.split(",")
                    item.face_price_num = item.face_price_num.split(",")
                    return item
                })
            }).catch(e => {
                this.$message.warning(e.message)
            })
        },
        // 验证表单
        submitForm(formName) {
            if (this.showStatus === "see") {
                this.showCoupons = !this.showCoupons
                return false
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.shopvoucherOrder(this.showStatus)
                } else {
                    return false
                }
            })
        },
        voucherOrderEditData(data) {
            let par = {
                order_no: this.order_no,
                ...data
            }
            par.pay_prove = JSON.stringify(par.pay_prove)
            voucherOrderEdit(par).then(res => {
                this.$refs["ruleForm"].resetFields()
                this.moneyList = []
                this.fileList = []
                this.voucherOrderlistsData()
                this.showCoupons = !this.showCoupons
                this.$message.success("操作成功！")
            }).catch(e => {
                this.$message.warning("操作失败！")
            })
        },
        // 提交数据
        shopvoucherOrder(status) {
            let face_price = [] // 面值数组
            let face_price_num = [] // 面值数组对应的数量
            let moneyRes = true // 面值不能大于5000
            this.moneyList.map(item => {
                if (item.money > 5000) {
                    this.$message.warning("最大面值不能超过5000！")
                    moneyRes = false
                    return false
                }
                face_price.push(parseFloat(item.money))
                face_price_num.push(item.num)
            })
            let data = {
                ...this.shopCouponsData,
                face_price: JSON.stringify(face_price),
                face_price_num: JSON.stringify(face_price_num)
            }
            if (status === "add" && moneyRes) {
                delete data.pay_prove
                voucherOrderbuy(data).then(res => {
                    let resdata = res.data
                    this.$router.push({
                        path: '/member/shopSuccess',
                        query: {
                            order_no: resdata.order_no,
                            total_price: resdata.total_price
                        }
                    })

                    /*this.$message.success("操作成功！")
                    this.page.page = 1
                    this.$refs["ruleForm"].resetFields()
                    this.moneyList = []
                    this.voucherOrderlistsData()
                    this.showCoupons = !this.showCoupons*/
                }).catch(e => {
                    if (e.data == "超过购买数量限制!") {
                        this.$message.warning("消费券数量总和不能超过1000张！")
                    } else {
                        this.$message.warning(e.data)
                    }

                })
            } else if (status === "edit" && moneyRes) {
                this.voucherOrderEditData(data)
            }

        },
        // 新增消费券
        addMoney() {
            this.moneyList.push({
                money: 1,
                num: 1
            })
            this.calculation()
        },
        // 删除消费券
        delMoney(index) {
            this.moneyList.splice(index, 1)
            this.calculation()
        },
        // 计算总额
        calculation() {
            let all = 0
            this.moneyList.map(item => {
                let num = this.$util.Mul(item.money, item.num)
                all = this.$util.Add(all, num)
            })
            this.moneytotal = all
        },
        // 购买消费券
        shopCoupons() {
            this.disabledButton = false;
            this.showCoupons = !this.showCoupons
            this.showStatus = "add"
            this.disabledForm = false
            this.moneyList = [{
                money: 1,
                num: 1
            }]
            this.calculation()
            this.getGeneralListData() //发票信息
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
        },
        orgVoucherListData(item) {
            this.VoucherListshow = true
            this.Voucherloading = true
            this.VoucherPage.order_no = item ? item.order_no : this.VoucherPage.order_no
            orgVoucherList(this.VoucherPage).then(res => {
                this.Voucherloading = false
                let data = res.data
                this.VoucherList = data.list
                this.VoucherPageTotal = data.count
            })
        },
        openAuditReasonsList(item) {
            this.AuditReasonsShow = true
            this.AuditReasonsloading = true
            getAuditReasonsList({ order_no: item.order_no }).then(res => {
                this.AuditReasonsloading = false
                let data = res.data
                this.AuditReasonsList = data.list
            })
        },
        getGeneralListData(item) {
            getGeneralList().then(res => {
                this.generalList = res.data
            })
        },
        handleCurrentChange(val) {
            this.page.page = val
            this.voucherOrderlistsData()
            window.scrollTo(0, 0)
        },
        handleCurrentVoucherChange(val) {
            this.VoucherPage.page = val
            this.orgVoucherListData()
        }
    }
}
</script>

<style type="text/scss" lang="scss">
.el-icon-circle-close {
    color: #fff;
}

.member .el-dialog__body {
    padding: 0px 20px;
}

.layui-btn-xs {
    height: 22px;
    line-height: 22px;
    padding: 0 5px;
    font-size: 12px;
    margin-bottom: 5px;
}

.el-card__header {
    padding: 8px 20px
}

.el-table__header,
.el-table__body {
    width: 1000px
}
</style>
<style scoped type="text/scss" lang="scss">
.voucherListshow .el-table__header-wrapper table,
.voucherListshow .el-table__body-wrapper table {
    width: 100% !important;
}

.coupons_tip {
    font-size: 12px;
    height: 30px;
    line-height: 20px;
    color: $base-color;
}

.couponsTable {
    &_Con {
        display: flex;
        justify-content: space-between;
        text-align: center;

        >div {}
    }
}

.my-account {
    background: #ffffff;
    padding: 20px;
}

.coupons {
    &_title {
        display: flex;
        justify-content: space-between;
    }

    &_money {
        max-height: 200px;
        overflow: auto;

        &_list {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            >div:last-child {
                margin-left: 20px;
            }

            &_label {
                width: 50px;

                :before {
                    content: "*";
                    color: #f56c6c;
                    margin-right: 4px;
                }
            }

            &_input {
                width: 180px;
                margin-right: 20px;
            }
        }
    }
}

.consumption {
    &_head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &_table {
        margin-bottom: 10px;
    }

    &_page {
        ::v-deep .el-pagination {
            text-align: center;
        }
    }
}
</style>
